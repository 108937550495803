<script>
import { http } from "../../http";
import ListMessageTemplate from "../shared/message-template/ListMessageTemplate.vue";
import Loader from "../shared/painel/Loader.vue";

export default {
  components: {
    "list-message-template": ListMessageTemplate,
    Loader,
  },
  data() {
    return {
      loading: true,
      main_commands: null,
      tag: "",
      message: "",
      data: null,
      showModal: false,
      showAlertModal: false,
      alertMessageModal: "",
      showAlert: false,
      alertMessage: "",
      modalType: "",
      current_command_id: null,
    };
  },
  mounted() {
    this.getAllMainCommands();
  },
  methods: {
    show(mType, command_id) {
      this.modalType = mType;
      this.current_command_id = command_id;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
      this.showAlertModal = false;
    },
    getAllMainCommands: async function () {
      await http.get("painel/metodologia/list-all-main-commands/")
      .then((response) => (this.main_commands = response.data))
      .finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 1000);
      });
    },
    createNewTree() {
      if (this.tag === "") {
        this.showAlertModal = true;
        this.alertMessageModal = "O campo tag é obrigatório!";
        return;
      }

      var randomNumber = Math.floor(Math.random() * 9999) + 1000;
      var name = "#" + this.tag + randomNumber;
      var payload = {
        name: name,
        tag: this.tag,
        message: this.message,
        order_id: null,
        is_main: true,
        parent_command_id: null,
      };

      http
        .post("painel/metodologia/save-command-tree/", payload)
        .then((response) => (this.data = response.data))
        .finally(() => {
          this.hide();
          this.getAllMainCommands();
          this.showModal = false;
          this.showAlertModal = false;
          this.alertMessageModal = "";
          this.tag = "";
          this.message = "";

          // TODO: Refactor - Possibilidade de transformar numa função
          this.showAlert = true;
          this.alertMessage = "O Comando da Árvore foi CADASTRADO com Sucesso!";

          setTimeout(() => {
            this.showAlert = false;
            this.alertMessage = "";
          }, 5000);
        });
    },
    deleteCommandTree() {
      var payload = {
        id: this.current_command_id,
        delete_type: "complete_tree",
      };

      http
        .post("painel/metodologia/delete-command-tree/", payload)
        .then((response) => console.log("DELETE: " + response.data))
        .finally(() => {
          this.getAllMainCommands();
          this.showModal = false;
          this.showAlert = true;
          this.alertMessage = "O Comando da Árvore foi DELETADO com Sucesso!";

          setTimeout(() => {
            this.showAlert = false;
            this.alertMessage = "";
          }, 5000);
        });
    },
    editCommandTree(id) {
      this.$router.push("tree/" + id);
    },
    selectedItem(item) {
      this.message = item.id;
    },
  },
};
</script>
<template>
  <div class="container-fluid">
    <h1 class="h3 mb-4 text-gray-800">Árvore de Comandos</h1>
    <div v-if="this.showAlert" class="alert alert-success" role="alert">
      {{ this.alertMessage }}
    </div>

    <div>
      <router-link to="metodologia">
        <button class="btn btn-outline-secondary">
          <i class="bi bi-arrow-left-circle"></i> Voltar
        </button>
      </router-link>
    </div>

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <div class="d-flex w-100 justify-content-between align-items-center px-2">
          <h6 class="m-0 font-weight-bold text-primary">Tabela de Árvores</h6>

          <div align="right">
            <button
              type="button"
              class="btn btn-outline-primary"
              title="Criar Árvore"
              data-toggle="modal"
              @click="show('add', null)"
            >
              Criar Nova Árvore
            </button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Tag</th>
                <th>Nome</th>
                <th>Tem conteúdo?</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading !== true" v-for="command in main_commands" v-bind:key="command.id">
                <td class="align-middle">#{{ command.id }}</td>
                <td class="align-middle">{{ command.command_tag }}</td>
                <td class="align-middle">{{ command.name }}</td>
                <td class="align-middle">{{ command.message != null ? "SIM" : "NÃO" }}</td>
                <td class="align-middle col-1">
                  <div class="d-flex justify-content-evenly">
                    <button
                      @click="editCommandTree(command.id)"
                      type="button"
                      class="btn btn-primary"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
  
                    <button
                      @click="show('delete', command.id)"
                      type="button"
                      class="btn btn-danger"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-else>
                <td v-if="loading !== true" colspan="5" class="text-center">Nenhuma árvore de comandos encontrada.</td>
                <td v-else colspan="5" class="text-center">
                  <Loader />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div id="modal" v-if="showModal">
      <div id="demo-modal" class="modal-tree">
        <div class="content-modal">
          <h3 class="title-modal">
            {{
              this.modalType == "add"
                ? "Criar nova Árvore"
                : "Você deseja DELETAR toda a Árvore de Comandos?"
            }}
          </h3>

          <div
            v-if="this.showAlertModal"
            class="alert alert-danger"
            role="alert"
          >
            {{ this.alertMessageModal }}
          </div>

          <div v-if="this.modalType == 'add'" class="form-fields">
            <div class="form-group">
              <label for="recipient-tag" class="col-form-label">Tag:</label>
              <input
                type="text"
                placeholder="Tag"
                class="form-control"
                id="recipient-tag"
                v-model="this.tag"
              />
            </div>
            <div class="form-group">
              <label for="recipient-message" class="col-form-label"
                >Message Template:</label
              >
              <list-message-template
                @selectedItem="selectedItem"
              ></list-message-template>
            </div>
          </div>

          <div class="modal-footer">
            <button @click="hide" class="btn btn-modal btn-secondary">
              Fechar
            </button>
            <button
              v-if="this.modalType == 'add'"
              @click="createNewTree"
              class="btn btn-modal btn-primary"
            >
              Salvar
            </button>
            <button
              v-else
              @click="deleteCommandTree"
              class="btn btn-modal btn-danger"
            >
              Deletar
            </button>
          </div>

          <a @click="hide" class="close-modal">&times;</a>
        </div>
      </div>
    </div>
    <!-- Fim do Modal -->
  </div>
</template>
<style scoped>
.card {
  margin-top: 1%;
}
</style>