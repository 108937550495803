<template>
    <div class="modal fade" id="configuracao" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Configurações</h5>
            <button class="close" type="button" data-dismiss="modal">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="configurations">
              <div class="form-group">
                <label for="setclient">Cliente Atual:</label>
                <input type="text" class="form-control" id="setclient" :value="configurations.setclient" readonly />
              </div>
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="is_report_enabled"
                  :checked="configurations.is_report_enabled"
                  @change="$emit('update:isReportEnabled', $event.target.checked)"
                />
                <label class="form-check-label" for="is_report_enabled">Relatório Habilitado</label>
              </div>
              <div class="form-group">
                <label for="list_clients">Selecionar Cliente:</label>
                <select
                  class="form-control"
                  id="list_clients"
                  :value="selectedClient"
                  @change="$emit('update:selectedClient', parseInt($event.target.value))"
                >
                  <option v-for="client in configurations.list_clients" :key="client.id" :value="client.id">
                    {{ client.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
            <button class="btn btn-primary" @click="$emit('save')" v-if="selectedClient" data-dismiss="modal">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      configurations: Object,
      selectedClient: Number,
      isReportEnabled: Boolean,
    },
    emits: ["update:selectedClient", "update:isReportEnabled", "save"],
  };
  </script>
  
  <style scoped>
  /* Estilos personalizados, se necessário */
  </style>
  