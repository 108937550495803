<script>
import { http_file } from "../../http";

export default {
  name: "ImportOptions",
  props: {
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      modalAction: "",
      showImportSection: false,
      alertMessage: "",
      showAlert: false,
      isLoading: false,
      alertType: "primary",
    };
  },
  methods: {
    importCsv() {
      const file = document.getElementById("file").files[0];
      if (!file || file.type !== "text/csv") {
        this.alertMessage = "Por favor, selecione um arquivo CSV válido.";
        this.alertType = "danger";
        this.showAlert = true;
        return;
      }
      this.isLoading = true;
      this.alertMessage = "Carregando...";
      this.alertType = "primary";
      this.showAlert = true;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("course_id", this.courseId);
      http_file
        .post("/auto_courses/import-options-to-registration-page/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.alertMessage = response.data.detail;
          this.alertType = response.status === 200 ? "success" : "warning";
          this.showAlert = true;
        })
        .catch((error) => {
          this.alertMessage =
            error.response.data.detail || "Erro ao importar o arquivo.";
          this.alertType = "danger";
          this.showAlert = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toggleImportSection() {
      this.showImportSection = !this.showImportSection;
    },
    downloadExample() {
      const csvContent =
        "field_name,option_name,option_label,option_value,option_order_id,filter_by\n";
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "exemplo.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-secondary"
        @click="toggleImportSection"
      >
        Importar Opções para LP
      </button>
    </div>
    <div v-if="showImportSection" class="mt-2 w-75 mx-auto">
      <div
        v-if="showAlert"
        :class="['alert', `alert-${alertType}`, 'my-4']"
        role="alert"
      >
        {{ alertMessage }}
      </div>
      <label for="file" class="form-label">Arquivo CSV para Importar:</label>
      <div class="input-group input-group-outline">
        <input
          id="file"
          type="file"
          class="form-control"
          name="file"
          placeholder="File"
        />
        <button
          type="button"
          :class="['btn', 'btn-primary']"
          @click="importCsv()"
          :disabled="isLoading"
        >
          Importar CSV
        </button>
        <button
          type="button"
          :class="['btn', 'btn-secondary', 'ml-2']"
          @click="downloadExample"
        >
          Baixar Exemplo
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>