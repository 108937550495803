<template>
    <li class="nav-item active collapsed">
      <a
        class="nav-link collapsed"
        data-toggle="collapse"
        :data-target="'#' + id"
        aria-expanded="true"
        :aria-controls="id"
      >
        <i class="fas fa-fw" :class="icon"></i>
        <span>{{ title }}</span>
      </a>
      <div
        :id="id"
        class="collapse submenu-full"
        aria-labelledby="headingPages"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner submenu-collapse">
          <h6 class="collapse-header">Opções</h6>
          <div v-for="route in filteredRoutes" :key="route.path">
            <router-link :to="route.path" class="collapse-item d-flex w-100">
              {{ route.titulo }}
            </router-link>
          </div>
        </div>
      </div>
    </li>
  </template>
  
  <script>
  export default {
    props: {
      id: String,
      title: String,
      icon: String,
      category: String,
      routes: Array,
    },
  
    computed: {
      filteredRoutes() {
        return this.routes.filter(route => route.categoria === this.category);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Adicione estilos personalizados, se necessário */
  </style>
  