import { http } from "../http";
import { createRouter, createWebHashHistory } from "vue-router";
import { useCookies } from "vue3-cookies";

import Chat from "../components/chat/Chat.vue";
import Painel from "../components/shared/painel/Painel.vue";
import CommandTree from "../components/command-tree/CommandTree.vue";
import TreeList from "../components/command-tree/TreeList.vue";
import Cadastro from "../components/cadastro/Cadastro.vue";
import Enrolments from "../components/Enrolments.vue";
import Login from "../components/auth/Login.vue";
import Client from "../components/client/Client.vue";
import Metodologia from "../components/metodologia/metodologia.vue";
import Projetos from "../components/metodologia/projetos.vue";
import CuratedList from "../components/lms/CuratedList.vue";
import EnrollmentsInformation from "../components/customer-success/EnrollmentsInformation.vue";
import PushBatchesInformation from "../components/customer-success/PushBatchesInformation.vue";
import PushBatch from "../components/customer-success/PushBatch.vue";
import MessagesInformation from "../components/customer-success/MessagesInformation.vue";
import DetailCourse from "@/components/metodologia/DetailCourse.vue";
import DetailLesson from "@/components/metodologia/DetailLesson.vue";
import ListInfoCourse from "@/components/metodologia/ListInfoCourse.vue";
import SenderControl from "@/components/metodologia/SenderControl.vue";
import DetailSender from "@/components/metodologia/DetailSender.vue";
import Profile from "@/components/profile/Profile.vue";
import Biblioteca from "@/components/news_latter/Biblioteca.vue";

const modelCookies = useCookies();

const routes = [
  {
    path: "/",
    redirect: "/login", // Redireciona para login por padrão
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/painel",
    name: "Painel",
    component: Painel, // 🔹 Painel.vue será o layout principal
    meta: { requiresAuth: true },
    children: [
      { path: "", name: "painel-default", component: Chat },    
      { path: "chat", name: "chat", component: Chat },
      { path: "tree/:id", name: "tree", component: CommandTree },
      { path: "tree-list", name: "tree-list", component: TreeList },
      { path: "cadastro", name: "cadastro", component: Cadastro },
      { path: "enrolments", name: "enrolments", component: Enrolments },
      { path: "client", name: "client", component: Client },
      { path: "metodologia", name: "metodologia", component: Metodologia },
      { path: "projetos", name: "projetos", component: Projetos },
      { path: "profile", name: "profile", component: Profile },
      { path: "curated-list", name: "curated-list", component: CuratedList },
      { path: "enrollments-information", name: "enrollments-information", component: EnrollmentsInformation },
      { path: "push-batches-information", name: "push-batches-information", component: PushBatchesInformation },
      { path: "push-batch/:id", name: "push-batch", component: PushBatch },
      { path: "messages-information", name: "messages-information", component: MessagesInformation },
      { path: "course/:id", name: "detail-course", component: DetailCourse },
      { path: "lesson/:id/:course", name: "detail-lesson", component: DetailLesson },
      { path: "list-info-course", name: "list-info-course", component: ListInfoCourse },
      { path: "sender-control", name: "sender-control", component: SenderControl },
      { path: "detail-sender/:id", name: "detail-sender", component: DetailSender },
      { path: "biblioteca-lys", name: "biblioteca-lys", component: Biblioteca },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const token = useCookies().cookies.get("user_session") || localStorage.getItem("user_session");

  if (to.meta.requiresAuth && !token) {
      next({ name: "login" });
    } else if (token && !localStorage.getItem("first_name")) {
      try {
          const response = await http.post("info_user_for_token", { init_user: token });

          if (response.data) {
              localStorage.setItem("first_name", response.data.first_name);
              localStorage.setItem("last_name", response.data.last_name);
              localStorage.setItem("email", response.data.email);
              localStorage.setItem("is_admin", response.data.is_admin);
          }
      } catch (error) {
          console.error("Erro ao buscar info do usuário:", error);
          useCookies().cookies.remove("user_session");
          localStorage.clear();
          next({ name: "login" });
          return;
      }
  }

  next();
});

export default router;
