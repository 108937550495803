<template>
    <div class="card-body">
      <div class="row">
        <!-- Card 1: Total Senders -->
        <div class="col-lg-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100">
            <div class="card-body d-flex flex-column justify-content-between">
              <div>
                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                  Total de Senders
                </div>
                <div v-if="total_senders !== null" class="small text-gray-800">
                  {{ total_senders }}
                </div>
                <div v-else class="small text-gray-800">
                  Carregando...
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Card 2: Livres e Alocados -->
        <div class="col-lg-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100">
            <div class="card-body d-flex flex-column justify-content-between">
              <div>
                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                  Senders
                </div>
                <div v-if="allocated_senders_count !== null && free_senders_count !== null" class="small text-gray-800">
                  Alocados: {{ allocated_senders_count }} <br>
                  Livres: {{ free_senders_count }}
                </div>
                <div v-else class="small text-gray-800">
                  Carregando...
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Card 3: Países -->
        <div class="col-lg-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100">
            <div class="card-body d-flex flex-column justify-content-between">
              <div>
                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                  Países
                </div>
                <div v-if="countries !== null" class="small text-gray-800">
                  {{ countries.join(", ") }}
                </div>
                <div v-else class="small text-gray-800">
                  Carregando...
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Card 4: Contagem por País -->
        <div class="col-lg-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100">
            <div class="card-body d-flex flex-column justify-content-between">
              <div>
                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                  Contagem por País
                </div>
                <div v-if="country_counts !== null" class="small text-gray-800">
                  <ul class="mb-0">
                    <li v-for="(count, country) in country_counts" :key="country">
                      {{ country }}: {{ count }}
                    </li>
                  </ul>
                </div>
                <div v-else class="small text-gray-800">
                  Carregando...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="container-fluid">
      <div class="card shadow mb-4 mt-1">
        <div class="card-header py-3 d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-center gap-3">
            <h5 class="m-0 font-weight-bold text-primary">Tabela de Senders</h5>
            </div>
            <div>
                <label>
                    <input type="checkbox" v-model="showAllocated" @change="filterSenders">
                    Alocados
                </label>
                &nbsp;
                <label>
                    <input type="checkbox" v-model="showFree" @change="filterSenders">
                    Livres
                </label>
            </div>
            <div>
                <select 
                class="form-select" 
                style="height: 3rem; width: auto;" 
                v-model="selectedCountry" 
                @change="searchByCountry"
                >
                <option value="" >Filtrar por País</option>
                <option v-for="country in countries" :key="country" :value="country">
                    {{ country }}
                </option>
                </select>
            </div>
  
          <div id="filters" class="col-4 d-flex justify-content-end align-items-center gap-3">
            <input 
              type="text" 
              class="form-control" 
              style="height: 3rem;" 
              v-model="search_filter" 
              placeholder="Pesquisa de sender pelo número ou email" 
              @keyup.enter="searchByNumberOrEmail"
            >
            <button 
              class="btn btn-info d-flex align-items-center justify-content-center fw-bold" 
              style="height: 2.5rem; width: 2.5rem; border-radius: 2.5rem;" 
              @click="list_all_senders"
            >
              <i class="bi bi-search"></i>
            </button>
          </div>
        </div>
  
        <ModalSenders :fields="sender_id" />
  
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Numero / Email</th>
                  <th scope="col">País</th>
                  <th scope="col">Verificado</th>
                  <th scope="col">Integrador</th>
                  <th scope="col">Quality Rating</th>
                  <th scope="col">Alocado</th>
                  <th scope="col">Capacidade</th>
                  <th scope="col">Proprietário</th>
                  <th scope="col">Detalhes</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="loading !== true && loading_pagination !== true && list_senders.length"
                  v-for="sender in list_senders"
                  :key="sender.id"
                >
                  <td>{{ sender.phone_number || 'Não cadastrado'}}</td>
                  <td>{{ sender.country || 'Não cadastrado'}}</td>
                  <td>
                    <i class="bi" 
                       :class="{
                         'bi-check-circle text-success': sender.verified === true,
                         'bi-x-circle text-danger': sender.verified === false
                       }">
                    </i>
                  </td>
                  <td>{{ sender.provider || 'Não cadastrado'}}</td>
                  <td>
                    <i class="bi" 
                       :class="{
                         'bi-check-circle text-success': sender.quality_rating === 'green',
                         'bi-exclamation-circle text-warning': sender.quality_rating === 'yellow',
                         'bi-x-circle text-danger': sender.quality_rating === 'Red'
                       }"></i>
                  </td>
                  <td class="clickable" data-bs-toggle="modal" data-bs-target="#modalDatailsSender" @click="set_sender(sender.id)">
                    {{ sender.count_course }}
                  </td>
                  <td>{{ sender.number_capacity || 'Não cadastrado'}}</td>
                  <td>{{ sender.owner || 'Não cadastrado'}}</td>
                  <td>
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        :disabled="!user.is_admin"
                      >
                        Opções
                      </button>
                      <div class="dropdown-menu" v-if="user.is_admin">
                        <a class="dropdown-item" :href="'/?#/painel/detail-sender/' + sender.id">Ver Detalhes</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-else>
                  <td v-if="loading !== true && loading_pagination !== true" colspan="15" class="text-center">
                    Nenhum sender encontrado.
                  </td>
                  <td v-else colspan="15" class="text-center">
                    <Loader />
                  </td>
                </tr>
              </tbody>
            </table>
  
            <div v-if="loading !== true && loading_pagination !== true && this.list_senders" class="pagination">
              <button v-if="currentPage>1" class="btn-pagination" @click="prevPage" :disabled="currentPage === 1"><i class="bi bi-arrow-left-circle" style="font-size: 1.3rem;"></i></button>
              <span>&nbsp; {{ currentPage }} de {{ totalPages }} &nbsp;</span>
              <button v-if="currentPage < totalPages" class="btn-pagination" @click="nextPage" :disabled="currentPage === totalPages"><i class="bi bi-arrow-right-circle" style="font-size: 1.3rem;"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
    import { http } from "../../http";
    import Loader from "../shared/painel/Loader.vue";
    import { useCookies } from "vue3-cookies";
    import ModalSenders from "../shared/ModalSenders.vue";
    
    export default {
      name: "SenderControl",
      components: {
          Loader,
          ModalSenders,
      },
    
      data() {
        return {
          loading: true,
          loading_pagination: false,
          list_senders: [],
          currentPage: 1,
          totalPages: 1,
          search_filter: '',
          user: {
              is_admin: useCookies().cookies.isKey("user_session")
          },
          sender_id:'',
          total_senders: null,
          allocated_senders_count: null,
          free_senders_count: null,
          countries: null,
          country_counts: null,
          selectedCountry:"",
          showAllocated: false,
          showFree: false,
        };
      },
    
      mounted() {
        this.list_all_senders(this.currentPage);
      },
    
      methods: {
        formatDate(dateString) {
          const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
          return new Date(dateString).toLocaleDateString('pt-BR', options);
        },
    
        async list_all_senders(page) {
          if (this.selectedCountry === "Mexico") {
                this.selectedC = "México";
            }else{
                this.selectedC = this.selectedCountry
            }
          const payload = {
            current_page: parseInt(page),
            search_filter: this.search_filter.toString(),
            search_country: this.selectedC,
            allocated_senders: this.showAllocated,
            free_sender: this.showFree,
          };
          await http.post("painel/atend_comercial/list_sender/", payload)
          .then((res) => {
              this.list_senders = res.data.results;
              this.totalPages = res.data.total_pages;
              this.total_senders = res.data.total_senders;
              this.allocated_senders_count = res.data.allocated_senders_count;
              this.free_senders_count = res.data.free_senders_count;
              this.countries = res.data.countries;
              this.country_counts = res.data.country_counts;
          })
          .catch((error) => {
            console.error('Erro detail sender:', error);
          })
          .finally(() => {          
            setTimeout(() => {
                this.loading = false;
                this.loading_pagination = false;
            }, 1000);
          });      
        },   
        
        set_sender(sender_id){
          this.sender_id = sender_id
        },
    
        searchByNumberOrEmail() {
          this.loading_pagination = true;
          this.currentPage = 1;
          this.list_all_senders(this.currentPage);
        },

        searchByCountry(){
            this.loading_pagination = true;
            this.currentPage = 1;
            this.list_all_senders(this.currentPage);
        },

        filterSenders(){
            this.loading_pagination = true;
            this.currentPage = 1;
            this.list_all_senders(this.currentPage);
        },

        nextPage() {
          if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.loading_pagination = true;
            this.list_all_senders(this.currentPage);
          }
        },
    
        prevPage() {
          if (this.currentPage > 1) {
            this.currentPage--;
            this.loading_pagination = true;
            this.list_all_senders(this.currentPage);
          }
        },
      },
    };
</script>
  
<style>
    .btn-pagination {
        margin: none;
        border: none;
        background-color: transparent;
        color: black;
        font-weight: bold;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
</style>
  