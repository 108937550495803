<template>
  <div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      <router-link to="/" class="sidebar-brand d-flex align-items-center justify-content-center" style="background-color: #95abec">
        <div class="d-flex justify-content-center w-100">
          <img class="fas" src="../../../assets/img/cropped-logo_lys.png" />
        </div>
      </router-link>

      <hr class="sidebar-divider my-0" />

      <div class="sidebar-heading fs-5 mb-5">Ambientes</div>

      <template v-if="is_admin">
        <SidebarMenu id="collapseNewsLetter" title="Novidades" icon="fa-folder" category="news_letter" :routes="routes" />
        <SidebarMenu id="collapseComercial" title="Comercial" icon="fa-folder" category="comercial" :routes="routes" />
        <SidebarMenu id="collapseMetodologia" title="Metodologia" icon="fa-folder" category="metodologia" :routes="routes" />
      </template>

      <SidebarMenu id="collapsePages" title="Interação" icon="fa-folder" category="interacao" :routes="routes" />
    </ul>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
          </button>

          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ name_user }}</span>
                <img class="img-profile rounded-circle" src="../../../assets/img/undraw_profile.svg" />
              </a>
              <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a class="dropdown-item">
                  <i class="bi bi-person-check" style="font-size: 1.3rem; color: #198754;"></i>
                  {{ email_user }}
                </a>
                <router-link to="/profile" class="dropdown-item">
                  <i class="bi bi-eye" style="font-size: 1.1rem;"></i>
                  Perfil
                </router-link>
                <a class="dropdown-item" style="cursor: pointer;" data-toggle="modal" data-target="#configuracao">
                  <i class="bi bi-gear" style="font-size: 1.1rem;"></i>
                  Configurações
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" style="cursor: pointer;" @click="logout">
                  <i class="bi bi-power" style="font-size: 1rem; color: red;"></i>
                  Sair
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <!-- End of Topbar -->

        <!-- Configuração Modal -->
        <ModalConfiguracao
          v-if="configurations"
          :configurations="configurations"
          :selectedClient="selectedClient"
          @update:selectedClient="selectedClient = $event"
          @save="saveConfigurations"
        />

        <!-- Router View para carregar os componentes das rotas -->
        <div class="container-fluid">
          <div class="row">
            <keep-alive include="chat,tree,tree-list,cadastro,profile">
              <transition name="pagina">
                <router-view></router-view>
              </transition>
            </keep-alive>
          </div>
        </div>
      </div>
      <!-- End of Main Content -->

      <!-- Footer -->
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>&copy; Lys Academy</span>
          </div>
        </div>
      </footer>
      <!-- End of Footer -->
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { http } from "../../../http";
import SidebarMenu from "../SidebarMenu.vue";
import ModalConfiguracao from "../ModalConfiguracao.vue";

export default {
  components: { SidebarMenu, ModalConfiguracao },

  data() {
    return {
      routes: [
        { path: "/painel/newsletter", categoria: "news_letter", titulo: "Newsletter" },
        { path: "/painel/biblioteca-lys", categoria: "news_letter", titulo: "Biblioteca Lys" },
        { path: "/painel/chat", categoria: "interacao", titulo: "Chat Tutor" },
        { path: "/painel/curated-list", categoria: "interacao", titulo: "Curadoria de Conteúdo" },
        { path: "/painel/client", categoria: "comercial", titulo: "Carteira de Clientes" },
        { path: "/painel/enrollments-information", categoria: "comercial", titulo: "Informações dos Alunos" },
        { path: "/painel/push-batches-information", categoria: "comercial", titulo: "Push Batches" },
        { path: "/painel/messages-information", categoria: "comercial", titulo: "Informações das Mensagens" },
        { path: "/painel/metodologia", categoria: "metodologia", titulo: "Controles" },
      ],
      name_user: "",
      email_user: "",
      is_admin: false,
      configurations: null,
      selectedClient: null,
    };
  },

  mounted() {
    this.initializePainel();
  },

  methods: {
    initializePainel() {      
      const firstName = localStorage.getItem("first_name");
      const lastName = localStorage.getItem("last_name");
      const email = localStorage.getItem("email");
      const isAdmin = localStorage.getItem("is_admin");
      
      if (firstName && lastName && email) {
        this.name_user = `${firstName} ${lastName}`;
        this.email_user = email;
        this.is_admin = JSON.parse(isAdmin || "false");

        this.getConfigurations();
      } else {
        this.logout();
      }
    },

    logout() {
      useCookies().cookies.remove("user_session");
      useCookies().cookies.remove("set_user_info");
      localStorage.clear();
      this.$router.replace("/login");
    },    

    async getConfigurations() {
      if (!this.configurations) {
        const payload = { token: localStorage.getItem("user_session") };
        await http.post("return-list-client/", payload)
          .then(response => {
            this.configurations = response.data;
          })
          .catch(error => console.error(error));
      }
    },

    saveConfigurations() {
      if (!this.selectedClient) return;

      const payload = {
        token: localStorage.getItem("user_session"),
        client: parseInt(this.selectedClient),
        is_report_enabled: this.configurations.is_report_enabled,
      };

      http.post("save-configurations/", payload)
        .then(response => console.log(response.data))
        .catch(error => console.error(error))
        .finally(() => this.getConfigurations());
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
</style>
