<template>
  <div v-if="!isLoaded" class="pagination">
    <!-- Botão "Primeira" -->
    <button
      v-if="currentPage > 1"
      class="btn-pagination"
      :disabled="currentPage === 1"
      @click="goToFirstPage"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M11 19l-7-7 7-7M18 19l-7-7 7-7" />
      </svg>
    </button>

    <!-- Botão "Anterior" -->
    <button
      v-if="currentPage > 1"
      class="btn-pagination"
      :disabled="currentPage === 1"
      @click="goToPreviousPage"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M15 18l-6-6 6-6" />
      </svg>
    </button>

    <!-- Exibição da página atual -->
    <span class="pagination-info">
      {{ currentPage }} de {{ totalPages }}
    </span>

    <!-- Botão "Próxima" -->
    <button
      v-if="currentPage < totalPages"
      class="btn-pagination"
      :disabled="currentPage === totalPages"
      @click="goToNextPage"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </button>

    <!-- Botão "Última" -->
    <button
      v-if="currentPage < totalPages"
      class="btn-pagination"
      :disabled="currentPage === totalPages"
      @click="goToLastPage"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M13 19l7-7-7-7M6 19l7-7-7-7" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isLoaded: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  methods: {
    goToFirstPage() {
      this.$emit("page-changed", 1);
    },
    goToPreviousPage() {
      this.$emit("page-changed", this.currentPage - 1);
    },
    goToNextPage() {
      this.$emit("page-changed", this.currentPage + 1);
    },
    goToLastPage() {
      this.$emit("page-changed", this.totalPages);
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.btn-pagination {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-pagination:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  font-size: 1rem;
  color: #333;
}
</style>