<template>
    <div
      class="modal fade"
      id="modalDatailsSender"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalDatailsSenderLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-info text-white d-flex align-items-center pr-4">
            <h3 class="modal-title" id="modalDatailsSenderLabel">Alocação do Sender</h3>
            <button
              type="button"
              class="btn-close bg-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="clearModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-header bg-info text-white fw-bold fs-4">Cursos</div>

              <div class="card-body">
                <ul class="list-group list-group-flush fs-5">
                  <li
                    v-for="(item, id) in list_courses"
                    :key="id"
                    class="list-group-item"
                  >
                    {{ item.course__name }}
                  </li>
                </ul>
              </div>
            </div>
  
            <div class="card">
              <div class="card-header bg-info text-white fw-bold fs-4">Histórico de Alocação do Sender</div>
              
              <div class="card-body">
                <ul class="list-group list-group-flush fs-5">
                  <li v-if="list_histroy && list_histroy.length > 0"
                      v-for="(item, id) in list_histroy"
                      :key="id"
                      class="list-group-item"
                  >
                      <div><strong>Curso:</strong> {{ item.course__name }}</div>
                      <div><strong>Cliente:</strong> {{ item.course__company__client__name }}</div>
                      <div><strong>Ativo:</strong> {{ item.course__is_active ? 'Sim' : 'Não' }}</div>
                  </li>

                  <li v-else class="list-group-item">
                      <div>Nenhum curso encontrado.</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  import { http } from "../../http";  
  import Loader from "./painel/Loader.vue";
  
  export default {
    name: "ModalSenders",
    
    components: {
      Loader,
    },

    props: {
      fields: {
        type: Number,
        required: true, 
      },
    },

    data() {
      return {
        loading: true, 
        list_courses: [],
        list_histroy:[],
      };
    },

    watch: {
      fields: {
        immediate: true,
        handler(newSenderId) {
          if (newSenderId) {
            this.sender_allocated(newSenderId);
          }
        },
      },
    },

    methods: {
      async sender_allocated(sender_id) {
        this.loading = true; 
        this.list_courses = []; 
  
        const payload = { sender_id };
  
        await http.post("painel/atend_comercial/sender_allocated/", payload)
        .then((res) => {
          if (res.data) {
            this.list_courses = res.data.alocated;
            this.list_histroy = res.data.history;
          } else {
            console.error("Estrutura de resposta inesperada:", res);
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar detalhes do sender:", error);
        })
        .finally(() => {
          this.loading = false; 
        });
      },
      
      clearModal() {
        this.list_courses = [];
        this.list_histroy = [];
      },
    },
  };
</script>
  
<style>

  .btn-pagination {
    margin: 0;
    border: none;
    background-color: transparent;
    color: black;
    font-weight: bold;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
</style>
  