<script>
import TreeItem from "./TreeItem.vue";
import { http } from "../../http";

const treeData = {
  id: -1,
  name: "",
  tag: "Carregando Árvore. POR FAVOR, AGUARDE...",
  message: "",
  is_main: true,
  order: -1,
  children: [],
};

export default {
  components: {
    TreeItem,
  },
  data() {
    return {
      treeData,
      main_commands: null,
    };
  },
  mounted() {
    this.getAllMainCommands();
    this.selectMainCommand();
  },
  methods: {
    getAllMainCommands() {
      http
        .get("painel/metodologia/list-all-main-commands/")
        .then((response) => (this.main_commands = response.data));
    },
    selectMainCommand() {
      if (this.$route.params.id != null) {
        var payload = { main_command_id: this.$route.params.id };

        http
          .post("painel/metodologia/get-command-tree/", payload)
          .then((response) => (this.treeData = response.data));
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="card-tree">
      <div>
        <router-link to="/painel/tree-list">
          <button class="btn btn-outline-secondary">
            <i class="bi bi-arrow-left-circle"></i> Voltar
          </button>
        </router-link>
      </div>
      <div class="card">
        <div class="card-header bg-gradient-info text-light">
          <h4><i class="bi bi-diagram-3"></i> Árvore de Comandos</h4>
        </div>
        <div class="card-body">
          <ul>
            <TreeItem class="item" :model="treeData"></TreeItem>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item {
  cursor: pointer;
  line-height: 1.5;
  /* font-size: 20px; */
}
.color-black {
  color: black;
}
.card-tree {
  margin: 10px 140px;
}
.card {
  margin-top: 1%;
}
</style>