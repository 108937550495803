<template>
    <div class="background-login">
        <div class="container-fluid">
            <main class="form-signin">
                <form class="mt-4" style="width: 40% !important" @submit.prevent="Request_login">
                    <div class="d-flex justify-content-center w-100">
                        <img class="mb-2" src="../../../public/img/cropped-logo_lys.png" style="width: 80% !important;">
                    </div><br>
        
                    <div class="form-floating">
                        <span class="badge text-bg-primary fs-6">Email</span>
                        <input v-model="email" type="email" class="form-control input-padrao" required autofocus />
                    </div><br>
        
                    <div class="form-floating">
                        <span class="badge text-bg-primary fs-6">Senha</span>
                        <input v-model="password" type="password" class="form-control input-padrao" required />
                    </div>
        
                    <div v-if="erroLogin" class="alert alert-danger text-center mt-3">
                        Usuário ou senha inválidos!
                    </div>
        
                    <div class="d-flex justify-content-center w-100">
                        <button class="btn btn-primary mt-3 col-8 rounded-pill fs-5 fw-bold" type="submit">
                            Entrar
                        </button>
                    </div>
        
                    <div class="w-100 checkbox mb-3 d-flex justify-content-center">
                        <div v-if="loader">
                            <Loader />
                        </div>
                    </div>
                </form>
            </main>
        </div>
    </div>
</template>
  
<script>
  import { http } from "../../http";
import { useCookies } from "vue3-cookies";
import { useRouter } from "vue-router";
import Loader from "../shared/painel/Loader.vue";

export default {
  components: { Loader },

  data() {
    return {
      email: "",
      password: "",
      loader: false,
      erroLogin: false,
    };
  },

  setup() {
    const router = useRouter(); // 🔹 Instância do Vue Router
    return { router };
  },

  methods: {
    async Request_login() {
      this.loader = true;
      this.erroLogin = false;

      try {
        const response = await http.post("login", {
          email: this.email,
          password: this.password,
        });

        if (!response.data.access_token) {
          throw new Error("Token de acesso não recebido");
        }

        const token = response.data.access_token;
        useCookies().cookies.set("user_session", token, "7d"); // 🔹 Define expiração de 7 dias
        localStorage.setItem("user_session", token);

        const userInfo = await http.post(
            "info_user_for_token",
            { init_user: response.data.access_token },
            { 
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': "Token " + token, 
                    'X-CSRFToken': token 
                } 
            }
        );

        if (userInfo.data) {
            localStorage.setItem("first_name", userInfo.data.first_name);
            localStorage.setItem("last_name", userInfo.data.last_name);
            localStorage.setItem("email", userInfo.data.email);
            localStorage.setItem("is_admin", userInfo.data.is_admin);
        }

        this.loader = false;
        
        setTimeout(() => {
            this.$router.push("/painel");
        }, 500);
      } catch (error) {
        this.loader = false;
        this.erroLogin = true;
        useCookies().cookies.remove("user_session");
      }
    },
  },
};
</script>
  
<style scoped>
  .background-login {
    width: 100%;
    height: 100vh;
    display: flex;
    background-image: url("../../assets/img/banner-login.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .form-signin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    padding: 15px;
    margin-top: 5%;
  }
  
  .input-padrao {
    border: 1px solid #018cc8;
    max-height: 2em;
    padding: 1% 5% !important;
    box-shadow: 4px 4px #018cc895;
  }
  
  .input-padrao:focus {
    border: 1px solid #018cc8 !important;
    box-shadow: 4px 4px #018cc895 !important;
  }
  
  .alert {
    font-size: 14px;
  }
</style>
  