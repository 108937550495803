<template>
    <router-view></router-view>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { useRouter } from "vue-router";

export default {
    setup() {
        const router = useRouter();
        return { router };
    },

    async beforeMount() {
        var token = useCookies().cookies.get("user_session");
        
        if (!token) {
            this.router.push("/login");
        }       
    },
}
</script>
