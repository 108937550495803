<template>
    <div class="container-fluid">
    
        <!-- Page Heading -->
        <h1 class="h3 mb-2 text-gray-800">Olá {{ this.first_name }} {{ this.last_name }}</h1>
        <p class="mb-4">O email para acesso ao sistema é {{ this.email }}.</p>
        
    
       
    
      
    
        <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-start align-items-center gap-3">
                    <div>                
                        <h5 class="m-0 font-weight-bold text-primary">Biblioteca Lys</h5>
                    </div>

                </div>
            </div>
            <iframe src="https://www.notion.so/15a401805ea2800596d1e94f528071e8?v=15a401805ea281528b5b000ceea87db2&pvs=4" style="width: 100%; height: 100vh; border: 0; padding: 0"></iframe>
        </div>


        
    </div>
    </template>
    
    
      
    <script>
import Loader from "../shared/painel/Loader.vue";
import { http } from "../../http";
    export default {
        components: {
            Loader,
        },
        name: 'Biblioteca',
        data() {
            return {
               

            }
        },
        mounted() {
                   },
        methods: {
            
           
        }
    }
    </script>
      
      
    <style>
        .btn-pagination {
            margin: none;
            border: none;
            background-color: transparent;
            color: black;
            font-weight: bold;
        }

        .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }

        .profile-item {
            margin-bottom: 10px;
            border: 1px solid #706666;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 10px;
            border-radius: 5px;
        }
    </style>
    